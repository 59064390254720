import $ from 'jquery';

$(document).ready(function(){
    $('#menu-toggle').click(function(){
        const menu = $('#menu');

        $(this).toggleClass('open');

        $('#app-brand').toggleClass('app-brand_menu');
        $('#app-brand-icon').toggleClass('zero-opacity');
        $('#app-brand-icon-white').toggleClass('zero-opacity');
        $('header.header_root').toggleClass('header-in-menu');

        $(this).hasClass('open') ? menu.fadeIn(500) : menu.fadeOut(500);
    });
});